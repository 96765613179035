.product-category-admin-panel {
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
  margin-left: 40px;
  margin-top: 50px;
  gap: 20px;
  height: 88vh;

  .add-product-category {
    padding: 20px;
    width: 500px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-family: "Roboto", sans-serif;
    margin-bottom: 20px;
    height: 480px;

    .title-category {
      text-align: left;
      margin-bottom: 20px;
      font-family: "Roboto", sans-serif;
      font-weight: 600;
      color: #333;
      font-size: 24px;
    }

    form {
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: 10px;
        font-weight: 600;
        color: #555;
      }

      .input-category,
      .input-file {
        width: 100%;
        padding: 10px;
        margin-bottom: 20px;
        font-size: 16px;
        border: 1px solid #ddd;
        border-radius: 4px;
        box-sizing: border-box;
        font-family: "Roboto", sans-serif;
      }

      .input-file {
        padding: 5px;
      }

      .button-add-category {
        padding: 10px;
        background-color: #00a5a2;
        color: white;
        font-size: 16px;
        font-weight: 600;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;
        width: 40%;

        &:hover {
          background-color: #008d8b;
        }
      }

      .image-preview {
        margin-top: 20px;

        img {
          width: 100%;
          height: auto;
          border-radius: 4px;
          border: 1px solid #ddd;
        }
      }
    }
  }

  .product-categories-container {
    width: calc(100% - 600px);
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    font-family: "Roboto", sans-serif;

    .title-category-result {
      text-align: left;
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 24px;
      color: #333;
    }

    .category-card {
      background: #f9f9f9;
      padding: 15px;
      border-radius: 8px;
      margin-bottom: 10px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .category-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        img {
          width: 100px; /* Fixed size for the image */
          height: 100px;
          object-fit: cover; /* Ensures the image maintains its aspect ratio */
          border-radius: 8px;
          margin-bottom: 10px;
          border: 1px solid #ddd;
        }

        .category-name {
          font-weight: 700;
          color: #333;
        }

        .category-quantity {
          color: #666;
        }
      }

      .category-actions {
        display: flex;
        gap: 10px;

        button {
          padding: 5px 10px;
          font-size: 14px;
          color: white;
          background-color: #00a5a2;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.3s;

          &:hover {
            background-color: #008d8b;
          }
        }
      }
    }
  }
}
