.review-container {
  padding: 20px;
  margin-top: 60px;
}

.card-container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-left: 40px;
  margin-bottom: 190px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.review-card-entry,
.review-card-display {
  flex: 1;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  h2 {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    color: #333;
    margin-bottom: 15px;
  }
}

.review-card-entry {
  height: 400px;
  form {
    display: flex;
    flex-direction: column;

    div {
      margin-bottom: 15px;

      label {
        display: block;
        margin-bottom: 5px;
        font-weight: 600;
        color: #555;
      }

      .input-review {
        width: 90%;
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ddd;
        border-radius: 4px;
      }
    }

    .button-add-review {
      padding: 10px 20px;
      font-size: 16px;
      color: #fff;
      background-color: #00a5a2;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      text-align: center;
      width: 40%;

      &:hover {
        background-color: #008d8b;
      }
    }
  }
}

.review-card-display {
  overflow-y: scroll;

  .review-display-item {
    background: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between; /* Keep buttons on the right */
    align-items: center;

    .review-info {
      display: flex;
      flex-direction: column; /* Stack image, name, and description vertically */
      align-items: center;
      text-align: center; /* Center-align text content */

      .review-image {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 4px;
        margin-bottom: 10px; /* Space below the image */
      }

      .review-name {
        font-weight: 700;
        color: #333;
        margin-bottom: 5px; /* Space below the name */
        font-size: 16px;
      }

      .review-description {
        color: #666;
        font-size: 14px;
      }
    }

    .review-actions {
      display: flex;
      flex-direction: row; /* Align buttons horizontally */
      gap: 10px;

      button {
        padding: 5px 10px;
        font-size: 14px;
        color: white;
        background-color: #00a5a2;
        border: none;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: #008d8b;
        }
      }
    }
  }
}
