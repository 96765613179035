.news-container {
  margin-top: 50px;
  padding: 20px;
}

.card-container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  height: 600px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.news-card-entry,
.news-card-display {
  flex: 1;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  h2 {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    color: #333;
    margin-bottom: 15px;
  }
}

.news-card-entry {
  form {
    display: flex;
    flex-direction: column;

    div {
      margin-bottom: 15px;

      label {
        display: block;
        margin-bottom: 5px;
        font-weight: 600;
        color: #555;
      }

      .input-news {
        width: 90%;
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ddd;
        border-radius: 4px;
      }
    }

    .button-add-news {
      padding: 10px 20px;
      font-size: 16px;
      color: #fff;
      background-color: #00a5a2;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      width: 40%;

      &:hover {
        background-color: #008d8b;
      }
    }
  }
}

.news-card-display {
  overflow-y: scroll;
  .news-display-item {
    background: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .news-info {
      display: flex;
      flex-direction: column;

      .news-image {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 4px;
        margin-bottom: 10px;
      }

      .news-title {
        font-weight: 700;
        color: #333;
      }

      .news-description,
      .news-url,
      .news-date {
        color: #666;
        margin-top: 5px;
      }
    }

    .news-actions {
      display: flex;
      gap: 10px;

      button {
        padding: 5px 10px;
        font-size: 14px;
        color: white;
        background-color: #00a5a2;
        border: none;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: #008d8b;
        }
      }
    }
  }
}
