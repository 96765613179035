.dashboard-container {
  padding: 20px;
  margin-left: 40px;
  margin-top: 50px;
  height: 81vh;

  .welcome-section {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-bottom: 30px;

    h1 {
      font-family: "Playfair Display", serif;
      font-weight: 600;
      font-size: 45px;
      color: #333;
      margin-bottom: 10px;
    }

    p {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      color: #666;
    }
  }

  .features-section {
    h2 {
      font-family: "Playfair Display", serif;
      font-weight: 600;
      font-size: 45px;
      color: #333;
      margin-bottom: 20px;
      text-align: center;
    }

    .features-grid {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;

      .feature-card {
        flex: 1 1 200px;
        max-width: 300px;
        background-color: #ffffff;
        border-radius: 8px;
        padding: 15px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        text-align: center;

        .feature-icon {
          font-size: 40px;
          color: #00a5a2;
          margin-bottom: 10px;
        }

        h3 {
          font-family: "Roboto", sans-serif;
          font-size: 18px;
          font-weight: 600;
          color: #333;
          margin-bottom: 10px;
        }

        p {
          font-family: "Roboto", sans-serif;
          font-size: 14px;
          color: #666;
        }
      }
    }
  }
}
