.sidebar {
  width: 220px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #082c4c;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: width 0.3s;

  &.collapsed {
    width: 60px;

    .names-cat .section-label {
      display: none;
    }

    h2 {
      font-size: 12px;
    }

    .logout-name {
      display: none;
    }

    .icon {
      margin-right: 10;
    }

    .logout-button {
      justify-content: center;
      width: 60px;
    }
  }

  .sidebar-top {
    margin-top: -15px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h2 {
    margin-top: 10px;
    font-size: 25px;
    margin-bottom: 20px;
    text-align: center;
    font-family: "Kumbh Sans", sans-serif;
    font-weight: 500;
    background-color: transparent;

    &.admin-panel {
      font-size: 20px;
    }
  }

  .hr-line {
    background-color: rgb(223, 218, 218);
    margin-top: -10px;
    height: 1px;
    width: 100%;
  }

  .section-label {
    font-size: 15px;
    color: #aaa;
    margin: 10px 0 5px;
    width: 100%;
    text-align: left;
    font-family: "Kumbh Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
  }

  nav ul {
    list-style: none;
    padding: 0;
    width: 90%;
  }

  nav ul li {
    float: left;
    width: 100%;
    height: 50px;
    margin-bottom: 20px;

    a {
      display: flex;
      align-items: center;
      padding: 0px;
      width: 100%;
      text-decoration: none;
      color: white;
      background-color: transparent;
      border-radius: 5px;
      transition: background-color 0.3s;
      font-size: 14px;
      font-family: "Kumbh Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 500;

      .icon {
        margin-right: 10px;
      }

      &.active,
      &:hover {
        &:hover {
          background-color: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }

  .logout-button {
    width: 150px;
    padding-top: 10px;
    padding-bottom: 10px;

    padding-right: 5px;
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    display: flex;

    transition: background-color 0.3s;
    margin-bottom: 2rem;

    .icon {
      margin-right: 10px;
    }

    &:hover {
      background-color: #ff1a1a;
    }
  }

  .toggle-button {
    width: calc(100% - 40px);
    margin: 0 auto;
    padding: 10px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
    margin-bottom: 2rem;

    &:hover {
      background-color: #555;
    }
  }

  .sidebar {
    .active {
      background-color: #00a5a2;
      color: #fff;
      border-radius: 4px;

      .icon {
        color: #fff;
      }

      .names-cat {
        font-weight: bold;
      }
    }
  }

  @media (max-width: 768px) {
    width: 200px;

    .sidebar-image {
      width: 60px;
      height: 60px;
      margin-bottom: 15px;
    }

    h2 {
      font-size: 18px;
      margin-bottom: 15px;
    }

    nav ul li a {
      padding: 8px;
      font-size: 14px;

      .icon {
        margin-right: 8px;
      }
    }

    .logout-button {
      padding: 8px;
      font-size: 14px;
      width: calc(100% - 30px);
      margin: 0 auto;

      .icon {
        margin-right: 8px;
      }
    }
  }

  @media (max-width: 480px) {
    width: 100%;
    height: auto;
    position: static;
    flex-direction: row;
    align-items: center;
    padding: 10px;

    .sidebar-top {
      flex-direction: row;
      align-items: center;
    }

    .sidebar-image {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      margin-bottom: 0;
    }

    h2 {
      font-size: 16px;
      margin-bottom: 0;
    }

    nav {
      flex-grow: 1;

      ul {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        li {
          margin: 0;

          a {
            padding: 5px;
            font-size: 12px;
          }
        }
      }
    }

    .logout-button {
      padding: 5px;
      font-size: 12px;
      width: calc(100% - 20px);
      margin: 0 auto;

      .icon {
        margin-right: 5px;
      }
    }
  }
}
